<script>
import appConfig from '@src/app.config'
import Layout from '@layouts/main'
import Pagination from '@src/components/base/pagination'
import PageHeader from '@components/page-header'
import { BASE_IMAGE_URL } from '@src/config/configs'
import axios from '@/axios'

/**
 * Contents component
 */
export default {
  page: {
    title: 'Business',
    meta: [{ name: 'description', content: appConfig.description }],
  },
  components: { Layout, Pagination, PageHeader },
  data() {
    return {
      baseImageUrl: BASE_IMAGE_URL,
      title: 'Business',
      dataBusiness: [],
      pagination: {},
      filecsv: null,
      page: 1,
      file1: null,
      searchData : ''

    }
  },
  mounted() {
    this.onLoad()
  },
  methods: {
    sumbitSearch() {
      this.onLoad()
    },
    onPage(page) {
      this.page = page
      this.onLoad()
    },
    async onLoad() {
      await this.axios
        .get('/api/business',{
          params: {
            page: this.page,
            size: 10,
            search : this.searchData,
          },
        })
        .then((res) => {
          this.dataBusiness = res.data.data
          this.pagination = res.data
        })
        .catch((err) => {
          alert(err.response.data.error.message)
        })
    },

    async handleClickDeleteContent(id) {
      var confirmDel = confirm('Are you sure you want to delete?')
      if (confirmDel) {
        await this.axios
          .delete(`/api/business/${id}`)
          .then((result) => {
            this.onLoad()
          })
          .catch((err) => {
            alert(err.response.data.error.message)
          })
      }
    },

    async handleClickEditContent(data) {
      console.log(data)
      this.$router.push({
        path: '/business/edit',
        query: { id: data._id },
      })
    },
    async uploadCsv() {
      const data = new FormData()
      data.append('csv', this.filecsv)
      await axios.post('/api/business/uploadcsv', data)
      this.$router.go()
    },
 /*    onPage(page) {
      this.page = page
      this.axios
        .get(`/api/business?page=${page}`, {
          params: {
            page: this.page,
            size: 10,
            search : this.searchData,
          },
        })
        .then((res) => {
          this.dataBusiness = res.data.data
          this.pagination = res.data
        })
        .catch((err) => {
          alert(err.response.data.error.message)
        })
    }, */
  },
}
</script>

<template>
  <Layout>
    <div class="row page-title align-items-center">
      <div class="col-md-3 col-xl-4">
        <h4 class="mb-1 mt-0">Business</h4>
      </div>

      <div class="col-xl-8">
    <div class="row align-items-center">
      <div class="col-md-8">
        <router-link to="/business/create">
          <button
            class="btn btn-primary rounded-pill width-lg float-right aligncenter"
          >
            <feather type="file-plus"></feather>Create
          </button>
        </router-link>
        <button
          class="btn btn-success rounded-pill width-lg float-right aligncenter mr-2"
          v-b-modal.modal-center
        >
          <feather type="file-plus"></feather>Upload CSV
        </button>
      </div>
      <div class="col-md-4">
        <b-input-group>
          <b-form-input v-model="searchData"></b-form-input>
          <b-input-group-append>
            <b-button  @click="sumbitSearch" variant="dark">search</b-button>
          </b-input-group-append>
        </b-input-group>
      </div>
    </div>
  </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="table-responsive">
              <table class="table mb-0">
                <thead class="thead-light">
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Image</th>
                    <th scope="col">Title</th>
                    <th scope="col">action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(business, index) in dataBusiness" :key="index">
                    <td scope="row">{{ index + 1 }}</td>
                    <td
                      v-if="business.image[0]"
                      scope="row"
                      style="width: 340px; height: 195px;"
                    >
                      <img
                        :src="`${baseImageUrl}/${business.image[0].url}`"
                        alt
                        class="img-fluid"
                        style=""
                      />
                    </td>
                    <td v-else></td>

                    <td scope="row" style="width: 35%; height: 10%;">
                      {{ business.title }}
                    </td>

                    <td class="d-flex">
                      <a
                        class="btn btn-warning p-0 px-2 py-1 mr-2"
                        :href="'/business/edit/' + business._id"
                        >Edit</a
                      >
                      <b-button
                        @click="handleClickDeleteContent(business._id)"
                        class="btn btn-danger p-0 px-2 py-1"
                        >Delete</b-button
                      >
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <Pagination :data="pagination" :page="page" @onPage="onPage($event)" />

         
  
          </div>
        </div>
      </div>

      <!-- modal -->
      <b-modal id="modal-center" centered title="Upload Business" hide-footer>
        <div>
          <b-form-file
            v-model="filecsv"
            :state="Boolean(file1)"
            placeholder="Choose a file or drop it here..."
            drop-placeholder="Drop file here..."
          ></b-form-file>
          <div class="mt-3"
            >Selected file: {{ filecsv ? filecsv.name : '' }}</div
          >
          <b-button variant="outline-primary float-right" @click="uploadCsv"
            >upload</b-button
          >
        </div>
      </b-modal>
    </div>
  </Layout>
</template>

<style lang="scss">
.sizeImage {
  width: 50%;
  height: 50%;
}
</style>
